import React from "react";

export const Image = ({ title, smallImage }) => {
  // Function to handle image click
  const handleImageClick = (e) => {
    e.preventDefault(); // Prevent the default anchor link behavior
    window.open(smallImage, '_blank'); // Open the image in a new tab
  };

  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {/* Adding onClick handler to open the image in a new tab */}
        <a href={smallImage} title={title} onClick={handleImageClick}>
          <div className="hover-text">
            <h4>{title}</h4>
          </div>
          <img src={smallImage} className="img-responsive" alt={title} />
        </a>
      </div>
    </div>
  );
};
