export const ProjectGallery = (props) => {
    return (
      <div id="projectGallery" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Project Showcase</h2>
            <p>See the transformations we've made</p>
          </div>
          <div className="row">
            {props.ProjectGallery
              ? props.ProjectGallery.map((project, i) => (
                  <div key={i} className="col-md-6 col-lg-4 project-item">
                    <div className="project-image">
                      <div className="image-container">
                        <a href={project.beforeImage} target="_blank" rel="noopener noreferrer">
                          <img src={project.beforeImage} alt={`${project.title} Before`} />
                          <p className="image-label">Before</p>
                        </a>
                      </div>
                      <div className="image-container">
                        <a href={project.afterImage} target="_blank" rel="noopener noreferrer">
                          <img src={project.afterImage} alt={`${project.title} After`} />
                          <p className="image-label">After</p>
                        </a>
                      </div>
                    </div>
                    <h3>{project.title}</h3>
                    <p>{project.description}</p>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  };
  